.product-search-wrapper {
  position: relative;
  width: 100vw;

  .product-search-inner {
    width: $inner-width-container-big;
    margin: auto;
    display: flex;
    flex-direction: column;

    .header-search-product {
      width: 100%;
      background: #484848;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 10px;
      color: white;
      font-weight: 700;
      border-radius: 0px 0px $border-radius $border-radius;
      cursor: pointer;
    }

    .search-product-container {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      justify-content: space-around;

      .search-navigation {
        max-width: 25%;

        .nav-card-wrapper {
          // margin-bottom:15px;
        }

        .title-section-nav {
          margin-top: 15px;
          padding: 10px;
          font-family: $headline-font;
          letter-spacing: 1px;
          color: $header-background;
        }

        // colapse card button action
        .src-nav-colsed {
          height: 303px;
          overflow: scroll;
          border-radius: $border-radius $border-radius 0px 0px !important;
        }

        .open-all-card {
          // position: absolute;
          background: rgb(245, 245, 245);
          width: 100%;
          text-align: center;
          padding: 10px;
          bottom: 0;
          font-size: 9pt;
          cursor: pointer;
          border-radius: 0px 0px $border-radius $border-radius;
          color: grey;
          -webkit-transition: height 2s;
          transition: 0.2s;
        }

        .src-nav-card {
          position: relative;
          border-radius: $border-radius;
          width: 100%;
          color: $bodyFontColor;
          box-shadow: 0 0 1px #bbb, 0 1px 2px #f5f5f5;
          font-size: 11pt;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          -webkit-transition: height 2s;
          transition: 0.2s;

          ul {
            margin: 0;
            padding: 0;
            margin-bottom: 15px;
            list-style: none;

            li {
              padding: 10px;
              padding: 10px 20px;
              cursor: pointer;
              display: flex;
              flex-direction: row;

              .text-filter {
                padding: 0px 15px;
                padding-right: 0px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
              }

              &:hover {
                background: #e2e2e2;

                .chip-count {
                  background: white;
                }
              }
            }
          }
        }
      }

      .chip-count {
        border-radius: 24px;
        font-size: 9pt;
        padding-left: 5px;
        padding-right: 5px;
        background: #e8e8e8;
      }

      .pr-wrapper {
        width: 80%;
        padding-left: 3%;

        .prs-header {
          display: flex;
          flex-direction: column;

          .prs-breadcrumb {
            width: 100%;
            padding: 10px;
            font-size: 9pt;
            color: grey;
            ul {
              margin: 0;
              padding: 0;
              list-style: none;
              display: flex;
              flex-direction: row;

              li {
                margin: 0;
                padding: 5px;

                &:after {
                  content: ">";
                  margin-left: 10px;
                }

                &:last-child:after {
                  content: "";
                }
              }
            }
          }

          .filter-nav-mobile {
            display: none;
          }

          .prs-wrap {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            .prs-title {
              font-size: 16pt;
              padding-left: 15px;
            }

            .filter-open {
              height: 120px !important;
              display: flex;
              align-items: center;
            }

            .filter-wrapper {
              position: absolute;
              background: rgb(248, 248, 248);
              width: 150px;
              top: 100%;
              right: 0;
              height: 0;
              overflow: hidden;
              z-index: 80;
              border-radius: 0px 0px $border-radius $border-radius;
              box-shadow: 0 8px 6px -6px black;
              color: $bodyFontColor;
              font-size: 10pt;
              font-weight: 700;
              -webkit-transition: height 2s;
              transition: 0.2s;

              ul {
                margin: 0;
                padding: 0;
                list-style: none;
                width: 100%;

                li {
                  padding: 10px;
                  padding: 10px;
                  cursor: pointer;
                  display: flex;
                  flex-direction: row;

                  &:hover {
                    background: #e2e2e2;
                  }
                }
              }
            }
          }

          .tag-filter {
            display: flex;
            flex-direction: row;

            ul {
              list-style: none;
              display: flex;
              flex-direction: row;
              margin: 0;
              padding: 0;

              li {
                margin: 0px 2px;
                font-size: 9pt;
                background: #e0e0e0;
                color: $bodyFontColor;
                padding: 5px 15px;
                border-radius: 24px;

                i {
                  background: grey;
                  color: #e0e0e0;
                  text-align: center;
                  padding: 5px;
                  border-radius: 50%;
                  width: 22px;
                  height: 22px;
                  margin-left: 8px;
                  margin-right: -8px;
                  cursor: pointer;

                  &:hover {
                    background: rgb(88, 88, 88);
                    color: #e0e0e0;
                  }
                }

                &:last-child:hover {
                  background: rgb(177, 177, 177) !important;
                  cursor: pointer;
                }

                .fa-trash {
                  margin-right: 10px;
                  margin-left: -8px;
                  background: none;
                  color: $bodyFontColor;
                }
              }
            }
          }

          .prs-pag-wrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .prs-pagination {
              margin-top: 10px;
              justify-content: space-between;
              display: flex;
              flex-direction: row;
              min-width: 66px;
              color: grey;
            }

            ul {
              margin: 0px;
              padding: 0px;
              list-style: none;
              display: flex;
              flex-direction: row;

              li {
                margin: 0px;
                width: 30px;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0px 10px;

                &:hover {
                  background: rgb(241, 241, 241);
                  border-radius: 24px;
                }
              }

              .highligthed {
                background: rgb(241, 241, 241);
                border-radius: 24px;
              }
            }

            .arrow-left,
            .arrow-right {
              background: rgb(223, 223, 223);
              color: white;
              width: 23px;
              height: 23px;
              // border:1px solid grey;
              border-radius: 24px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              letter-spacing: 0px;
              cursor: pointer;
              margin: 0px 10px;

              &:hover {
                background: grey;
              }
            }
          }

          .prwc {
            padding-left: 15px;
            padding-top: 15px;

            .prwc-thumb-wrap {
              float: left;
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    /// untuk Filter by Price Class
    .price-min-wrapper {
      display: flex;
      flex-direction: column;
      padding: 8px;
      width: 100%;

      span {
      }

      input {
        width: 100%;
        height: 40px;
        border: 1px solid rgb(196, 196, 196);
        border-radius: 5px;
        padding: 9px;
        font-size: 12pt;
        text-align: right;
        color: $bodyFontColor;

        &:before {
          content: "min";
        }
      }
    }

    .submit-price-filter {
      width: 100%;
      padding: 10px;
      cursor: pointer;
      border: none;
      font-size: 10pt;
      color: $bodyFontColor;
      outline: none;
      background: whitesmoke;
      border-radius: $border-radius;
    }

    // filter button
    button {
      padding: 10px;
      background: rgb(241, 241, 241);
      cursor: pointer;
      border-radius: $border-radius;
      outline: none;
      font-size: 10pt;
      border: none;
      color: $bodyFontColor;

      &:hover {
        background: rgb(214, 214, 214);
      }
    }
  }
}

@media screen and (max-width: $break-small) {
  .product-search-wrapper {
    .product-search-inner {
      width: $inner-width-container-medium;

      .search-product-container {
        margin-top: 10px;

        .search-navigation {
          display: none;
        }

        .pr-wrapper {
          width: 100%;
          padding-left: 0;

          .prs-header {
            .filter-nav-mobile {
              display: flex;
              width: 100%;
              padding: 10px 0px;
              position: relative;

              .button-nav-wrapper {
                display: flex;
                width: 100%;
                border: none;
                justify-content: space-around;
                align-items: center;
                background: rgb(241, 241, 241);
                border-radius: $border-radius;
                overflow: hidden;

                button {
                  width: 100%;
                  padding: 10px;
                  background: none;
                  cursor: pointer;
                  outline: none;
                  font-size: 10pt;
                  border: none;

                  &:hover {
                    background: rgb(214, 214, 214);
                  }
                }
              }

              .open-filter {
                height: 70vh !important;
                display: flex;
                align-items: center;
                // min-height: 20vh !important;
              }

              .mobile-cat-filter {
                position: absolute;
                background: rgb(248, 248, 248);
                top: 90%;
                z-index: 80;
                width: 95%;
                height: 0vh;
                overflow: scroll;
                box-shadow: 0 8px 6px -6px black;
                -webkit-transition: height 2s;
                transition: 0.2s;

                ul {
                    margin: 0;
                    padding: 0 0px;
                    width: 100%;
                    margin-bottom: 15px;
                    list-style: none;

                  li {
                    padding: 10px;
                    padding: 10px 20px;
                    cursor: pointer;                    
                    display: flex;
                    flex-direction: row;
                    font-weight: 700;
                    color: $bodyFontColor;

                    .text-filter {
                        padding: 0px 15px;
                        padding-right: 0px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                    }

                    &:hover {
                      background: #e2e2e2;

                      .chip-count {
                        background: white;
                      }
                    }
                  }
                }
              }
            }

            .prs-wrap {
              // filter in web page
              button {
                display: none;
              }

              .filter-wrapper {
                display: none;
              }
            }

            .prwc {
              padding: 0;
              width: 100%;
              margin-top: 20px;
              margin-left: 0px;
              display: flex;
              justify-content: space-around;
              flex-wrap: wrap;

              h2 {
                text-align: center;
                height: 50vh;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .prwc-thumb-wrap {
                margin-right: 0px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .product-search-wrapper {
    .product-search-inner {
      width: 90%;

      .search-product-container {
        .pr-wrapper {
          .prs-header {
            .tag-filter {
              ul {
                li {
                  margin: 0px 2px;
                  font-size: 7pt;
                  background: #e0e0e0;
                  color: #505050;
                  padding: 2px 10px;
                  border-radius: 24px;

                  i{
                    padding: 6px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
