
.product-card-wrapper{
    position: relative;  
    width: 170px;
    height: 300px;
    background: white;
    border-radius:$border-radius;
    cursor: pointer;
    text-decoration: none;

    &:hover{
        box-shadow: $box-shadow;    
    }

    .product-card-inner{
        height: 100%;
        
        .product-card-display{            
            height: 180px;   
            background: white;                   
            border-radius: $border-radius $border-radius 0px 0px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .product-title-card{
            color:black;
            position: relative;
            padding: 10px;
            
            .title-product-card{
                font-size: 14px;
                text-transform: capitalize;
                height: 37.998px;
                overflow: hidden;
                font-weight: 400;
                margin-bottom: 6px;
            }

            .product-price-card{
                font-size: 15px;
                line-height: 1.4;
                font-weight: 700;
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-top: 10px;
                color: $color-secondary;

                .product-price__original{
                    display: block;
                    font-size: 12px;
                    line-height: 1.4;
                    margin-right: 6px;
                    font-weight: 400;
                    text-decoration: line-through;
                    color: #999;
                }

                .product-price__reduced{
                    display: block;
                }
            }
        } 
    }
}

@media screen and (max-width:$break-small) {

    .product-card-wrapper{
        position: relative;  
        width: 38vw;
        height: 300px;        
        border-radius:$border-radius;
        cursor: pointer;
        text-decoration: none;
    
        &:hover{
            box-shadow: $box-shadow;    
        }
    
        .product-card-inner{
            height: 100%;
            
            .product-card-display{            
                height: 180px;   
                background: white;                   
                border-radius: $border-radius $border-radius 0px 0px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
    
            .product-title-card{
                color:black;
                position: relative;
                padding: 10px;
                
                .title-product-card{
                    font-size: 14px;
                    text-transform: capitalize;
                    height: 37.998px;
                    overflow: hidden;
                    font-weight: 400;
                    margin-bottom: 6px;
                }
    
                .product-price-card{
                    font-size: 15px;
                    line-height: 1.4;
                    font-weight: 700;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: 10px;
                    color: $color-secondary;
    
                    .product-price__original{
                        display: block;
                        font-size: 12px;
                        line-height: 1.4;
                        margin-right: 6px;
                        font-weight: 400;
                        text-decoration: line-through;
                        color: #999;
                    }
    
                    .product-price__reduced{
                        display: block;
                    }
                }
            } 
        }
    }
  
}
