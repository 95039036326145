.product-detail-wrapper{
    position: relative;
    width: 100vw;
    color:rgba(0, 0, 0, 0.7);

    .inner-product-detail{
        width: 80%;        
        margin: auto;
        display: flex;
        padding-top: 20px;

        .product-image-wrapper{
            width: 100vw;
            display: flex;
            flex-direction: row;
            margin-top:30px; 

            .image-nav{
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li{
                        margin-bottom: 5px; 
                        cursor: pointer;                       

                        .thbn-prod{
                            height: 100px;
                            width: 100px;
                        }
                    }
                }
            }

            .viewers-image{
                margin-left: 10px;
                margin-right: 30px;
                width: 100%;

               
                .prmry-thmb {
                    width: 100%;
                    display: block;

                    &:after{
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }

                }
            }

        }
            
        .ptw{
            display: flex;
            flex-direction: column;
            min-width: 375px;
            max-width: 375px;

            // Judul
            .pdh{

                h1{
                    font-size: 24px;
                    letter-spacing: normal;
                    font-weight: 700;
                    line-height: 36px;                    
                }
            }

            // Penialaian

            .spdh{
                display:flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                .pnln-rtg{
                    display: flex;
                    flex-direction: row;
                    color: $color-secondary;
                    border-right: 1px solid grey;
                    padding-right: 10px;
                }

                .pnln-ct{
                    padding-left:10px;
                    padding-right:10px;
                }
            }

            // Harga
            .prc-ctn{
                margin-top:20px;
                width: 100%;
                background:$grey-color-background;
                border-radius: $border-radius;
                
                .hfsbn{
                    background: $color-secondary;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: $headline-font;
                    letter-spacing: 1px;
                    color: white;
                    padding: 10px 20px;
                    border-radius:  $border-radius $border-radius 0px 0px ;
                }

                .prc-wrp{
                    padding: 15px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .product-price{
                        font-size: 1.875rem;
                        font-weight: 700;
                    }

                    .product-price__original{
                        font-size: 2rem;
                        color: $color-secondary;
                        font-weight: 700;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                    }


                    .product-price__reduced{   
                        display: flex;  
                        flex-direction: row;      

                        .ori-price{
                            text-decoration: line-through;
                            // margin-right: 15px;
                            font-size: 1.375rem;
                            color: grey;    
                                                                
                        }
                    }

                    .ppd-off{
                        padding: 5px 15px;
                        background: #ffcc00;
                        color:#424242;
                        font-size: 1rem;
                        margin-left: 10px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }



                    

                }
            }

            // Button Beli
            .cta-wrapper{

                margin-top: 25px;
                
                .cta-btn{
                    padding: 15px 30px;
                    cursor: pointer;
                    font-family: $body-font;
                    outline: none;
                    border-radius: $border-radius;
                    border-right: none;
                    border-left: none;
                    border-top:none;      
                    width: 100%;
                    text-align: center;
                    color:white;
                    font-size: 22pt;
                    background: $color-secondary;
                    border-bottom: 4px solid $color-secondary-dark;              

                    
                    &:hover{
                        background: $color-secondary-light;
                        border-bottom: 4px solid $color-secondary;              
                    }

                    // &:active{
                    //     border-bottom: 0px solid $color-secondary;         
                    //     transform: translateY(4px);   
                    //     margin-top:10px;                        
                    // }    
                }

                // untuk Update Quantity
                .qty-rwp{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;

                    .hdl-prod-wrp{   
                        display: flex;
                        flex-direction: row;  
                        align-items: center;                   

                        .chg-qty-btn{
                            padding: 10px 15px;
                            border: 1px solid grey;
                            // border-radius: $border-radius;                            
                            cursor: pointer;
                            margin: 0px 5px; 
                            height: 45px;                         
                        }

                        .plus{
                            border-radius: 0px $border-radius $border-radius 0px;
                            margin-left: 0px;
                            border-left: none;
                        }

                        .minus{
                            border-radius:  $border-radius 0px 0px $border-radius;
                            margin-right: 0px;
                            border-right: none;
                        }
    
                        .input-chg-qty{
                            padding: 10px;
                            text-align: center;
                            font-size: 12pt;
                            font-weight: 500;
                            max-width: 65px;
                            border-radius: 0px;
                            outline: none;
                            border: 1px solid gray;
                        }
                    }

                    .cta-btn-update{
                        padding: 10px 20px;
                        cursor: pointer;
                        font-family: $body-font;
                        outline: none;
                        border-radius: $border-radius;
                        border-right: none;
                        border-left: none;
                        border-top:none;      
                        // width: 100%;
                        text-align: center;
                        color:white;
                        font-size: 15pt;
                        background: $attention-color;
                        border-bottom: 4px solid $attention-color-dark;              

                        
                        &:hover{
                            background: $attention-color-light;
                            border-bottom: 4px solid $attention-color-dark;              
                        }

                        // &:active:enabled{
                        //     border-bottom: 0px solid $color-secondary;         
                        //     transform: translateY(4px);   
                        //     margin-top:10px;                        
                        // } 
                        
                        &:disabled{                           
                            background: $attention-color;
                            border-bottom: 0px;
                            opacity: 0.3;
                            
                        }
                    }
                }

                .pd-author{
                    margin-top:30px;
                    
                    .title{
                        padding-bottom: 10px;
                        border-bottom: 1px solid whitesmoke;
                        font-size: 14pt;
                        margin-bottom:10px;
                    }

                    .author-card{
                        background: #f5f5f5;
                        padding: 4px 20px;
                        margin-top: 7px;
                        border-radius: 5px;
                    }
                }
    
            }

            // Lanjut Pembayaran
            .checkout-wrapper{
                margin-top:30px;
                background: $grey-color-background;
                padding: 15px;

                .cta-btn-checkout{
                    margin-top: 20px;
                    padding: 15px 30px;
                    cursor: pointer;
                    font-family: $body-font;
                    outline: none; 
                    border-radius: $border-radius;
                    border-right: none;
                    border-left: none;
                    border-top:none;      
                    width: 100%;
                    text-align: center;
                    color:white;
                    font-size: 15pt;
                    background: $color-secondary;
                    border-bottom: 4px solid $color-secondary-dark; 

                    
                    &:hover{
                        background: $color-secondary-light;
                    }
                   
                }
                
                .orback{
                    margin-top:10px;

                    strong{
                        color:$color-secondary;
                    }
                }
            }
        }

        // Deskripsi bagian bawah
        .pdesc-wrapper{
            display: flex;
            flex-direction: row;
            width: 100%;
            
            .pd-phy{
                width:50%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                text-align: left;
                // text-transform: capitalize;   
                
                .pdescw{
                    padding: 10px;
                    flex: 1 0 25%;

                    .ptitle{
                        font-size: 11pt;
                    }
    
                    .pvalue{
                        font-weight: 700;
                        font-size: 15pt;
                    }
                }

            }

            .pd-desc{
                width: 50%;
                text-align: justify;
                padding: 20px;
                padding-right: 0px;

                .title{
                    padding-bottom: 10px;
                    border-bottom: 1px solid whitesmoke;
                    font-size: 14pt;
                }
            }

        }


    }
}

.contain-pict{
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.zoom-ipbpress{
    background-position-y: 0% !important;
    background-position-x: 90% !important;
    background-size: 120% auto !important;
}

@media screen and (max-width:$break-small){
    .product-detail-wrapper{
        
        .inner-product-detail{
            width: 100%;
            flex-direction: column;
            
            .product-image-wrapper{
                display: flex;
                flex-direction: column;
                width: 100vw;
                margin: 0;

                .image-nav{
                    order: 1;
                    flex-direction: column;
                    

                    ul{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;

                        li{
                            margin-right: 5px;
                            box-shadow: $box-shadow;

                            .thbn-prod{
                                height: 80px;
                                width: 80px;
                            }
                        }
                    }
                }

                .viewers-image{
                    margin:0;
                    margin-bottom: 10px;
                }
            }

            .ptw{
                min-width: 0px;
                width: $inner-width-container-small;
                margin: auto;
                
            }

            // deskripsi bagian bawah
            .pdesc-wrapper{
                display: flex;
                flex-direction: column;
                width: 100%;
                
                .pd-phy{
                    width:100%;                   
    
                }
    
                .pd-desc{
                    width: 100%;                    
                }
    
            }

        }
    }
}

@media screen and (max-width:$break-medium){
    .product-detail-wrapper{
        .inner-product-detail{
            width: $inner-width-container-medium;
            
            .product-image-wrapper{
                display: flex;
                flex-direction: column;
                position: relative;
                right: 15px;

                .image-nav{
                    order: 1;
                    flex-direction: column;

                    ul{
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;

                        li{

                            margin-right: 5px;

                            .thbn-prod{
                                height: 80px;
                                width: 80px;
                            }
                        }
                    }
                }

                .viewers-image{
                    margin: 0;
                    margin-bottom: 10px;
                }
            }

            .ptw{
                 min-width: 0px;
                .cta-wrapper{

                    .qty-rwp{
                        flex-direction: column;
                    }
                    .cta-btn-update{
                        margin-top:20px;

                        &:active:enabled{
                            border-bottom: 0px solid $color-secondary;         
                            transform: translateY(4px);   
                            margin-top:30px !important;                        
                        } 
                        
                        // &:disabled{
                        //     padding: 15px 30px;
                        //     background: $color-secondary-light;
                        //     border-bottom: 0px;
                        //     opacity: 0.5;
                            
                        // }
                    }
                }
            }
        }
    }
}