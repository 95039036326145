.auth-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width:100vw;
    overflow: scroll;
    z-index: 150;
    position: fixed;
    top:0;

    .auth-box{
        position: fixed;
        width: 500px;
        max-height: 100vh;
        background: white;
        z-index: 103;
        border-radius: $border-radius;
        padding:30px;        
        overflow: auto;
        // position:relative;

        .inner-box{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .header-auth{
                width: 100%;
                text-align: right;
                margin-bottom: 5px;
                display: flex;
                justify-content: space-between;
                
                svg{
                    cursor: pointer;
                }
            }

            .sos-icon{
                svg, img{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 10px;
                    fill: #ffffff;
                }
            }

            .button-login{
                width: 100%;
                margin: 5px 0px;
                height: 45px;
                border-radius: $border-radius;
                border:1px solid grey;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 10px;
                font-weight: 600;
                cursor: pointer;

            }
                    .fb {
                        background:#4366b4;
                        color:white;
                        border:none;
                    }
            
            .sparator{
                width: 100%;
                margin:10px 0px;
                text-align: center;
                overflow: hidden;                
                position: relative;

                .linehr{
                    border-bottom: 1px solid rgb(219, 219, 219);
                }
                
                &:before{
                    content: "";
                    border-bottom: 1px solid rgb(219, 219, 219);
                    position: absolute;
                    top: 50% !important;
                    left: 60% !important;
                    width: 5000px !important;
                }
                &:after{
                    content: "";
                    border-bottom: 1px solid rgb(219, 219, 219);
                    position: absolute;
                    top: 50% !important;
                    right: 60% !important;
                    width: 5000px !important;
                }

                span{
                }
            }

            .form-login{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items:center;
                justify-content: center;

                input{
                    width: 100%;
                    height: 45px;
                    border-radius: $border-radius;
                    padding: 10px;
                    margin: 8px 0px;
                    border: 1px solid rgb(219, 219, 219);
                    font-size: 12pt;
                    outline: none;
                }
            }

            .rem-toggle{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                text-align: right;
                font-size: 10pt;
            }

            .login-button{
                width: 100%;
                height: 45px;
                margin: 15px 0px;
                border-radius: $border-radius;
                font-size: 12pt;
                font-weight: 600;
                background: $color-secondary;
                color:white;
                outline: none;
                cursor: pointer;
                border:none;

                &:disabled{
                    background: rgb(184, 184, 184);
                }
            }

            .forgot-pasw{
                width: 100%;
                margin-bottom: 20px;
                text-align: center;
                font-size: 10pt;
            }

            .form-container{
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .label{
                    font-size: 9pt;
                    position: absolute;
                    right: 40px;
                    color: rgb(189, 189, 189);
                    display: block;
                }

                i{
                    position: absolute;
                    right: 10px;
                    color: rgb(189, 189, 189);
                }

            }

            .birth-form-container{
                
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                select{
                    width: 100%;
                    height: 45px;
                    border-radius: $border-radius;
                    padding: 10px;
                    margin: 8px 0px;
                    border: 1px solid rgb(219, 219, 219);
                    font-size: 12pt;
                    outline: none;
                    &:first-child{
                        margin-right:10px
                    }

                    &:last-child{
                        margin-left:10px;
                    }
                }
            }

        }
    }
}

.form-error{
    position: absolute;
    font-size: 8pt;    
    display: flex;
    right: 40px;
    padding: 5px;
    background: rgb(255, 90, 90);
    color: white;
    border-radius: $border-radius;
}


@media screen and (max-width:600px) {

    .auth-wrapper{

        .auth-box{
            // height: 100vh;
            width: 100%;
           
        }
    }
}