* {
    box-sizing: border-box;
}

.wrapper{
    position: relative;
    top:0px;
    height: 100vh;
    width: 100%;
    font-family: $body-font;

    &.wrapper-full-page{
        min-height: 100vh;
        height: auto;
    }

    .main-page{        
        position: relative;
        // background: pink;
        width: 100vw;
        margin-top: 65px;
        margin-bottom: 65px;
        // min-height: 100vh;
        height: auto;
    }
}