.invoice-wrapper{
    // background: grey;
    width: 705px;
    margin: auto;
    padding: 50px 10px;

    .header{
       padding-left: 40px;
    }

}

@media print {
    .no-print { display: none; }
}