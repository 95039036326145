.thanks-wrapper{
    position: relative;
    width: 100vw;

    .inner-thanks-wrapper{
        width: $inner-width-container-big;        
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top:50px;

        .thanks-body{
            display: flex;
            width: 600px;
            border: 1px solid rgb(199, 199, 199);
            padding: 30px;
            flex-direction: column;

            h3{
                width: 100%;
                display: flex;
                justify-content: center;
                text-align: center;
            }

            .box-timer{
                background: rgb(235, 235, 235);
                padding: 30px 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                justify-content: center;
                border-radius: $border-radius;                                                        
            }

            .norek{
                position: relative;
                margin-top:20px;
                padding-bottom: 20px;
                border-bottom: 1px solid rgb(243, 243, 243);
                margin-bottom: 10px;

                p{
                    margin:0px;
                }

                h2{
                    font-size: 20pt;
                    margin:10px 0px;
                }

                .copy-trigger{
                    position: absolute;
                    right: 0px;
                    top:0px;
                    cursor: pointer;
                    color: $attention-color-dark;
                    text-decoration: underline;
                    font-size: 10pt;

                    &:active{
                        color:white;
                        background: $color-secondary;
                        padding: 3px;
                    }
                }

                img{
                    position: absolute;
                    bottom:40px;
                    right: 0px;
                    height: 30px;
                }
            }

            .jyhd{
                position: relative;
                padding-bottom: 20px;
                border-bottom: 1px solid rgb(243, 243, 243);

                p{
                    margin:0;
                }

                .nominal-yhd{
                    margin-top:15px;
                    font-size: 20pt;
                    font-weight: 700;
                    color: $color-secondary-dark;
                }

                .highlight-important{
                    margin-top:20px;
                    width: 100%;
                    padding: 20px;
                    color: white;
                    background: rgb(110, 110, 110);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    h1{
                        margin: 0px;
                        font-size: 15pt;
                    }

                    p{
                        margin: 0;
                    }
                }

                .copy-total{
                    position: absolute;
                    right: 0px;
                    top:0px;
                    cursor: pointer;
                    color: $attention-color-dark;
                    text-decoration: underline;
                    font-size: 10pt;

                    &:active{
                        color:white;
                        background: $color-secondary;
                        padding: 3px;

                    }
                }
            }

            .thanks-foot{
                padding :20px 0px;
                text-align: center;
                font-weight: 400;
                font-size: 11pt;
                padding-bottom: 20px;
                border-bottom: 1px solid rgb(243, 243, 243);
            }

            
        }
    }

}

.btn-action-big{
    padding:20px;
    width: 100%;
    background: $color-secondary;
    border-radius: $border-radius;
    font-size: 15pt;
    color:white;
    cursor: pointer;
    border:none;
    outline: none;

    &:hover{
        background: $color-secondary-light;
    }

    &:active{
        outline: none;
    }
}

.clock-wrapper{
    padding: 20px;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;

    .clock-div{
        display: flex;                        
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;

        .number{
            font-size: 25pt;
            font-weight: 600;
            font-family: $headline-font;
        }

    }
}

@media screen and (max-width:654px){

    .thanks-wrapper{

        .inner-thanks-wrapper{
            width: $inner-width-container-small;
            padding-top: 20px;

            .thanks-body{

                .norek{

                    .copy-trigger{
                        position: relative;
                    }

                    img{
                        height: 20px !important;
                        bottom: 20px;
                    }
                }

                .jyhd{
                    .highlight-important{
                        text-align: center
                    }
                }
            }
        }
    }

}