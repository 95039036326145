.countdown-wrapper{
    padding: 20px;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    color: $bodyFontColor;

    .countdown-div{
        display: flex;                        
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;

        .number{
            font-size: 15pt;
            font-weight: 600;
            font-family: $headline-font;
        }

    }
}