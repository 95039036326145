.product-slider-wrapper{
    position: relative;
    width: 100vw;
    margin-top:50px;
    margin-bottom:30px;
    height: 380px;
    // background: yellow;

    .inner-product-slider{
        width: $inner-width-container-big; 
        margin: auto;
        height: 100%;
        // background: grey;
        display: flex;  
        flex-direction: column;  
        
       

        .product-slider-section-wrapper{
            display: flex;
            flex-direction: row;
            height: 100%;

            .product-slider-section-title{
                width: 17%;
                height: 100%;
                background: rgb(70, 70, 70);
                margin-right: 5px;
                cursor: pointer;
                border-radius: $border-radius;
            }
            
            .product-slick-carousel{
                width: 80%;
                margin-left: 30px;

                .title-section-product-slider{
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;                    
                    margin-left: 20px;
                    border-bottom: 3px solid whitesmoke; 
                    margin-bottom: 30px;
                    height: 42px;
        
                    .title-section-slider{
                        font-family: $headline-font;
                        color: bodyFontColor;
                        letter-spacing: 1px;
                        font-weight: 600;
                        font-size: 24px;
                        padding-bottom : 10px;
                        border-bottom: 3px solid $color-secondary; 

                    }
        
                    .look-all-product-slider{
                        color:$color-secondary;
                        font-size: 15px;
                        cursor: pointer;
                        text-align: right;
                    }
                }
    
                .product-slick-carousel-inner{
                    // width: 1800px;

                    .product-slide-item{
                        margin-right:5px;
                        margin-bottom:5px;
                    }
                    
                    div{
                        outline: none;                                                
                    }
                }
            }
        }        
    }
}

.product-card-slider-loading-wrapper{    
    height: 290px;
    overflow: hidden;
}

@media screen and (max-width:$break-small) {
    .product-slider-wrapper{
        margin-top: 10px;

        .inner-product-slider{
            width: $inner-width-container-small;

            // .title-secion-product-slider{

            // }
            
            .product-slider-section-wrapper{
                
                .product-slider-section-title{
                    display: none;
                }

                .product-slick-carousel{
                    width: 100%;

                    .title-section-product-slider{
                        
                    }

                    .product-slick-carousel-inner{
                        // width: 1800px;
    
                        .product-slide-item{
                           
                        }
                        
                        div{
                            outline: none;                                                
                        }
                    }
                }

                
            }
        }
    }
}

@media screen and (max-width:$break-medium) {
    .product-slider-wrapper{

        .inner-product-slider{
            width: $inner-width-container-medium;

            .product-slider-section-wrapper{
                
                .product-slider-section-title{
                    display: none;
                }
                
                .product-slick-carousel{
                    .title-section-product-slider{
                        margin-left: 0px;
        
                        // .title-section-slider{
                        // }
                        // .look-all-product-slider{
        
                        // }
        
                    }
                    width: 100%;
                    margin-left: 0px;
                }
            }
        }
    }
}


.loading-background {
    background:linear-gradient(-45deg, #e9e9e9, rgb(219, 219, 219)) !important;
    background-size: 300% 300% !important;
	-webkit-animation: Gradient 0.5s ease infinite !important;
	-moz-animation: Gradient 0.5s ease infinite !important;
	animation: Gradient 0.5s ease infinite !important;
}

.center-text {
  margin: auto;
  width: 3000px;
  padding: 10px;
  text-align: center;
  background-color: blue;
  color: white;
}


.container1 {
    position: relative;
    width: 50%;
  }
  
  /* Buat gambar menjadi responsif */
  .container1 img {
    width: 100%;
    height: auto;
  }
  
  /* Style tombol dan letakkan di tengah container / gambar */
  .container1 .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .container1 .btn:hover {
    background-color: black;
  }


